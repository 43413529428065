import React, {useState, useEffect} from "react";
import CashierStore from 'stores/CashierStore'
import CashierActions from 'actions/CashierActions'
import QualCodeActions from "../../actions/QualCodeActions";
import { Redirect } from 'react-router-dom';
import UserStore from "../../stores/UserStore";

function SubscriptionRedirectComponent(children) {
    const [subscriptionState, setSubscriptionState] = useState(CashierStore.getSubscriptions());

    const onChange = () => {
        setSubscriptionState(CashierStore.getSubscriptions());
    }

    useEffect(() => {
        CashierStore.addChangeListener(onChange);
        UserStore.addChangeListener(onChange);
        CashierActions.fetchSubscriptions();
        QualCodeActions.fetchUser();
        return () => {
            UserStore.removeChangeListener(onChange);
            CashierStore.removeChangeListener(onChange);
        }
    }, []);

    const subscription = ((subscriptionState || {}).subscriptionState || {}).subscription;

    if ( !subscription )
      return <></>
    else if ( subscription.status == 'canceled' )
    {
      if ( subscription.isOwner )
        return <Redirect to='/users'/>
      else
        return <></>
    }
    else {
      return <Redirect to='/welcome'/>
    }
}

export default SubscriptionRedirectComponent;